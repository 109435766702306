/* eslint-disable @next/next/no-img-element */
import Input from '@/components/inputs/input';
import LoadingOverlay from '@/components/loading';
import { PopupNoti } from '@/components/screen/common/PopupNoti';
import { isEmail, isValidPhoneNumber, toggleLanguage } from '@/helper/functions';
import useContentGlobal from '@/hooks/useContentGlobal';
import useStatePopupNotify from '@/hooks/useStatePopupNotify';
import contactApi from '@/services/contact';
import { collapseAction, collapseInit } from '@/utils/CollapseConfig';
import { SortCinema } from '@/utils/sortCinema';
import Link from 'next/dist/client/link';
import { useEffect, useRef, useState } from 'react';

type TListField = [nameField: string, valueCheck: string | boolean][];

const initErrorMessage = {
  name: "",
  email: "",
  content: "",
  phoneNumber: "",
}
type TProps = {
  listCinema: TCinima[]

}

export const ContactPage = ({ listCinema }: TProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorField, setErrorField] = useState(initErrorMessage);
  const { popupNotify, handleOpenNotify, handleClosedNotify } = useStatePopupNotify();
  const [listCinemaSort, setListCinemaSort] = useState<TCinima[]>([])

  const { getValueByKey } = useContentGlobal();

  const formRef = useRef<HTMLFormElement | any>(null);
  const handleSetErrorMessage = (message: string) => (
    listError: any,
    field: string,
    value: string | boolean
  ) => {
    if (!value) return { ...listError, [field]: message };
    return { ...listError, [field]: "" }
  }

  const checkEmptyField = handleSetErrorMessage(getValueByKey(["Message","Error","Empty_Field"]));
  const checkErrorEmail = handleSetErrorMessage(getValueByKey(["Message","Error","Email"]));
  const checkPhoneVN = handleSetErrorMessage(getValueByKey(["Message","Error","Phone_Number"]));

  const handleGetValueForm = (indexValue: number[]): string[] => {
    return indexValue.map((currentValue: number) => {
      return formRef.current?.[currentValue]?.value ?? ""
    }, [])
  }

  const handleCheckField = (
    listError: any,
    listField: TListField,
    funcCheck: (
      listError: any,
      field: string,
      value: string | boolean
    ) => any
  ) => {
    let _errorField = listError;
    listField.map(item => {
      _errorField = funcCheck(_errorField, item[0], item[1])
    });
    return _errorField;
  }

  const handleCheckValueForm = () => {
    if (formRef.current === null) return;
    const [name = "", email = "", phoneNumber = "", content = ""] = handleGetValueForm([0, 1, 2, 3]);

    const _listField: TListField = [
      ["name", name],
      ["email", email],
      ["phoneNumber", phoneNumber],
      ["content", content]
    ];
    let _errorField = handleCheckField(errorField, _listField, checkEmptyField);

    if (phoneNumber)
      _errorField = handleCheckField(_errorField, [["phoneNumber", isValidPhoneNumber(phoneNumber)]], checkPhoneVN);

    if (email && !isEmail(email)) {
      _errorField = handleCheckField(_errorField, [["email", isEmail(email)]], checkErrorEmail)
    }

    setErrorField(_errorField);
  }

  const handleSubmitForm = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);

    const [name = "", email = "", phoneNumber = "", content = ""] = handleGetValueForm([0, 1, 2, 3]);

    if (!isEmail(email) || !isValidPhoneNumber(phoneNumber)) return setIsLoading(false);

    try {
      const res: any = await contactApi.contactUS({
        input: {
          name,
          email,
          content,
          phone_number: phoneNumber,
        }
      });


      if (res?.sendContactInfo?.status === 200 || res) {
        e?.target?.reset();
        handleOpenNotify(getValueByKey(["Message","Success","Send"]));
        setIsLoading(false);
        return;
      }

      handleOpenNotify(getValueByKey(["Message","Error","Had_Error"]));
    } catch (error) {
      handleOpenNotify(getValueByKey(["Message","Error","Had_Error"]));
      setIsLoading(false);
      return;
    }

  }
  useEffect(() => {
    SortCinema(listCinema, setListCinemaSort)
    collapseInit()
  }, [listCinema, listCinemaSort])

  return (
    <>
      <PopupNoti
        isOpen={popupNotify.isOpen}
        handleClose={handleClosedNotify}
        contentTitle={popupNotify.message} />

      <div className="contact">
        <section className="contact-main ht">
          <div className="container">
            <div className="contact-main-wr">
              <div className="contact-main-heading sec-heading" data-aos="fade-up">
                <h1 className="heading">{getValueByKey("Contact")} CINESTAR</h1>
                <p className="desc">
                {getValueByKey(["News_Page", "Desc"])}</p>
              </div>
              <div className="contact-main-body mg-auto" data-aos="fade-up">
                <div className="form-contact">
                  <LoadingOverlay loading={isLoading}>
                    <form className="form" ref={formRef} onSubmit={handleSubmitForm}>
                      <p className="heading">
                        <span>{getValueByKey("Contact_Us")} </span>
                      </p>
                      {/* <p className="txt">
                        Lorem ipsum dolor sit amet, consectetur adipiscing
                      </p> */}
                      <div className="form-list">
                        <div className="form-it">
                          <label className="form-label" >{getValueByKey("Name")} </label>
                          <div>
                            <Input
                              className="form-control input "
                              type="text"
                              required
                              placeholder={getValueByKey("Full_Name")}
                              id="contact-main-fName"
                              messageError={errorField.name} />
                          </div>
                        </div>
                        <div className="form-it">
                          <label className="form-label" >Email</label>
                          <div>
                            <Input
                              className="form-control input"
                              type="email"
                              required
                              placeholder="Email"
                              id="contact-main-email"
                              messageError={errorField.email} />
                          </div>
                        </div>
                        <div className="form-it">
                          <label className="form-label">{getValueByKey("Phone_Number")}</label>
                          <div>
                            <Input
                              className="form-control input"
                              type="tel"
                              placeholder={getValueByKey("Phone_Number")}
                              id="contact-main-phone"
                              required
                              messageError={errorField.phoneNumber} />
                          </div>
                        </div>
                        <div className="form-it relative pb-[8px]">
                          <label className="form-label">{getValueByKey("Contact_Content")}</label>
                          <textarea className="form-control" required id="contact-main-mess" style={errorField.content ? {
                            border: "1.5px solid red"
                          } : {}}></textarea>
                          <div className="absolute bottom-[-6px] text-2xl text-error">{errorField.content}</div>
                        </div>
                        <div className="form-it"><button className="btn btn-submit btn--pri" type="submit" onClick={handleCheckValueForm}>{getValueByKey("Send")}</button></div>
                      </div>
                    </form>
                  </LoadingOverlay>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="contact-branch pb-80">
          <div className="container">
            <div className="contact-branch-wr">
              <div className="contact-branch-heading sec-heading" data-aos="fade-up">
                <div className="heading">
                  <h1>{getValueByKey(["Contact_Page","Our_Branches"])}</h1>
                </div>
                <div className="desc">
                  <p className="txt">{getValueByKey(["Contact_Page","Desc"])}</p>
                </div>
              </div>
              <div className="contact-branch-body">
                <div className="contact-branch-right">
                  <div className="branch-list address-list collapseBlockJS" onClick={(e) => collapseAction(e)}>
                    <div className="branch-item address-item collapseItem" data-aos="fade-up">
                      <div className="address-box">
                        <div className="branch-heading address-box-head collapseHead">
                          <h4 className="sub-tittle">TRỤ  SỞ | HEADQUATER</h4><i className="fa-solid fa-angle-down icon"></i>
                        </div>
                        <div className="branch-item-body collapseBody">
                          <p className="desc"> Cinestar Hai Bà Trưng nằm tại trung tâm Quận 1, gần nhiều trường đại học, trung tâm văn hóa và thương mại. Với vị trí đắc địa này, Cinestar Hai Bà Trưng là điểm đến giải trí ưa thích của nhiều khán giả trẻ. Với cam kết mang đến những trải nghiệm điện ảnh tuyệt vời nhất cùng giá vé hấp dẫn, Cinestar Hai Bà Trưng là lựa chọn hoàn hảo cho những ai yêu thích phim màn ảnh rộng.</p>
                        </div>
                        <div className="career-item-footer">
                          <ul className="list">
                            <li className="item"><Link className="link" target="_blank" rel='noreferrer' href="https://www.google.com/maps/place/Cinestar+Hai+B%C3%A0+Tr%C6%B0ng/@10.7825208,106.6978014,19.75z/data=!4m15!1m8!3m7!1s0x31752f36156267c7:0xfb2f71de82868922!2zMTM1IEhhaSBCw6AgVHLGsG5nLCBC4bq_biBOZ2jDqSwgUXXhuq1uIDEsIFRow6BuaCBwaOG7kSBI4buTIENow60gTWluaCwgVmnhu4d0IE5hbQ!3b1!8m2!3d10.7823432!4d106.6985216!16s%2Fg%2F11c2c46p1v!3m5!1s0x31752f366a781d37:0x1f5346650cf7ceb8!8m2!3d10.7826042!4d106.6981512!16s%2Fg%2F11d_1m2qsh?hl=vi-VN&entry=ttu"><span className="ic"><img src="/assets/images/ic-branch-map.svg" alt="" /></span><span className="txt">135 Hai Bà Trưng, Phường Bến Nghé, Quận 1</span></Link></li>
                            <li className="item"><Link className="link" target="_blank" rel='noreferrer' href="mailto:marketingcinestar@gmail.com"><span className="ic"><img src="/assets/images/ic-branch-mail.svg" alt="" /></span><span className="txt">marketingcinestar@gmail.com</span></Link></li>
                            <li className="item"><Link className="link" target="_blank" rel='noreferrer' href="tel:02873007279"><span className="ic"><img src="/assets/images/ic-branch-call.svg" alt="" /></span><span className="txt"> 028 7300 7279</span></Link></li>
                          </ul>
                          <ul className="lt-social">
                            <li className="it-social"><Link className="lk-social" target="_blank" rel='noreferrer' href="https://www.facebook.com/cinestarcinemasvietnam"> <img src="/assets/images/ic-fb-white.svg" alt="" /></Link></li>
                            <li className="it-social"><Link className="lk-social" href="https://zalo.me/2861828859391058401"> <img src="/assets/images/ic-zl-white.svg" alt="" /></Link></li>
                          </ul><Link className="btn btn--pri btn-submit" href="/about-us">Tìm hiểu thêm</Link>
                        </div>
                      </div>
                    </div>
                    {(listCinemaSort)?.map((item: TCinima) => (
                      <div className="branch-item address-item collapseItem" data-aos="fade-up" key={item?.id}>
                        <div className="address-box">
                          <div className="branch-heading address-box-head collapseHead">
                            <h4 className="sub-tittle uppercase">{toggleLanguage(item, "name")}</h4><i className="fa-solid fa-angle-down icon"></i>
                          </div>
                          <div className="branch-item-body collapseBody">
                            <p className="desc">{item?.description}</p>
                          </div>
                          <div className="career-item-footer">
                            <ul className="list">
                              <li className="item"><Link className="link" href={'tel:' + item?.telephone?.replace(/\s/g, "")} ><span className="ic"><img src="/assets/images/ic-branch-call.svg" alt="" /></span><span className="txt">{item.telephone}</span></Link></li>
                              <li className="item"><Link className="link" href={`${item?.maps_url}`} target="_blank" rel='noreferrer'><span className="ic"><img src="/assets/images/ic-branch-map.svg" alt="" /></span><span className="txt">{item.address}</span></Link></li>
                              <li className="item"><Link className="link" href={item?.facebook || ''}><span className="ic"><img src="/assets/images/ic-branch-fb.svg" alt="" /></span><span className="txt">{toggleLanguage(item, "name")}</span></Link></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    ))}
                    {/* <div className="branch-item address-item collapseItem" data-aos="fade-up">
                      <div className="address-box">
                        <div className="branch-heading address-box-head collapseHead">
                          <h4 className="sub-tittle">CINESTAR HAI BÀ TRƯNG </h4><i className="fa-solid fa-angle-down icon"></i>
                        </div>
                        <div className="branch-item-body collapseBody">
                          <p className="desc"> Cinestar Hai Bà Trưng nằm tại trung tâm Quận 1, gần nhiều trường đại học, trung tâm văn hóa và thương mại. Với vị trí đắc địa này, Cinestar Hai Bà Trưng là điểm đến giải trí ưa thích của nhiều khán giả trẻ. Với cam kết mang đến những trải nghiệm điện ảnh tuyệt vời nhất cùng giá vé hấp dẫn, Cinestar Hai Bà Trưng là lựa chọn hoàn hảo cho những ai yêu thích phim màn ảnh rộng. </p>
                        </div>
                        <div className="career-item-footer">
                          <ul className="list">
                            <li className="item"><a className="link" href=""><span className="ic"><img src="/assets/images/ic-branch-call.svg" alt="" /></span><span className="txt">028 7300 7279</span></a></li>
                            <li className="item"><a className="link" href=""><span className="ic"><img src="/assets/images/ic-branch-map.svg" alt="" /></span><span className="txt">271 Nguyễn Trãi, Phường Nguyễn Cư Trinh, Quận 1</span></a></li>
                            <li className="item"><a className="link" href=""><span className="ic"><img src="/assets/images/ic-branch-fb.svg" alt="" /></span><span className="txt">Cinestar Hai Bà Trưng</span></a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="branch-item address-item collapseItem" data-aos="fade-up">
                      <div className="address-box">
                        <div className="branch-heading address-box-head collapseHead">
                          <h4 className="sub-tittle">CINESTAR QUỐC THANH </h4><i className="fa-solid fa-angle-down icon"></i>
                        </div>
                        <div className="branch-item-body collapseBody">
                          <p className="desc"> Cinestar Hai Bà Trưng nằm tại trung tâm Quận 1, gần nhiều trường đại học, trung tâm văn hóa và thương mại. Với vị trí đắc địa này, Cinestar Hai Bà Trưng là điểm đến giải trí ưa thích của nhiều khán giả trẻ. Với cam kết mang đến những trải nghiệm điện ảnh tuyệt vời nhất cùng giá vé hấp dẫn, Cinestar Hai Bà Trưng là lựa chọn hoàn hảo cho những ai yêu thích phim màn ảnh rộng. </p>
                        </div>
                        <div className="career-item-footer">
                          <ul className="list">
                            <li className="item"><a className="link" href=""><span className="ic"><img src="/assets/images/ic-branch-call.svg" alt="" /></span><span className="txt">028 7300 7279</span></a></li>
                            <li className="item"><a className="link" href=""><span className="ic"><img src="/assets/images/ic-branch-map.svg" alt="" /></span><span className="txt">271 Nguyễn Trãi, Phường Nguyễn Cư Trinh, Quận 1</span></a></li>
                            <li className="item"><a className="link" href=""><span className="ic"><img src="/assets/images/ic-branch-fb.svg" alt="" /></span><span className="txt">Cinestar Quốc Thanh</span></a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="branch-item address-item collapseItem" data-aos="fade-up">
                      <div className="address-box">
                        <div className="branch-heading address-box-head collapseHead">
                          <h4 className="sub-tittle">CINESTAR STUDENT (BÌNH DƯƠNG) </h4><i className="fa-solid fa-angle-down icon"></i>
                        </div>
                        <div className="branch-item-body collapseBody">
                          <p className="desc"> Cinestar Hai Bà Trưng nằm tại trung tâm Quận 1, gần nhiều trường đại học, trung tâm văn hóa và thương mại. Với vị trí đắc địa này, Cinestar Hai Bà Trưng là điểm đến giải trí ưa thích của nhiều khán giả trẻ. Với cam kết mang đến những trải nghiệm điện ảnh tuyệt vời nhất cùng giá vé hấp dẫn, Cinestar Hai Bà Trưng là lựa chọn hoàn hảo cho những ai yêu thích phim màn ảnh rộng. </p>
                        </div>
                        <div className="career-item-footer">
                          <ul className="list">
                            <li className="item"><a className="link" href=""><span className="ic"><img src="/assets/images/ic-branch-call.svg" alt="" /></span><span className="txt">028 7300 7279</span></a></li>
                            <li className="item"><a className="link" href=""><span className="ic"><img src="/assets/images/ic-branch-map.svg" alt="" /></span><span className="txt">Nhà văn hóa Sinh Viên, ĐHQG Thành phố Hồ Chí Minh</span></a></li>
                            <li className="item"><a className="link" href=""><span className="ic"><img src="/assets/images/ic-branch-fb.svg" alt="" /></span><span className="txt">Cinestar Sinh viên ( Bình Dương)</span></a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="branch-item address-item collapseItem" data-aos="fade-up">
                      <div className="address-box">
                        <div className="branch-heading address-box-head collapseHead">
                          <h4 className="sub-tittle">CINESTAR HUẾ </h4><i className="fa-solid fa-angle-down icon"></i>
                        </div>
                        <div className="branch-item-body collapseBody">
                          <p className="desc"> Cinestar Hai Bà Trưng nằm tại trung tâm Quận 1, gần nhiều trường đại học, trung tâm văn hóa và thương mại. Với vị trí đắc địa này, Cinestar Hai Bà Trưng là điểm đến giải trí ưa thích của nhiều khán giả trẻ. Với cam kết mang đến những trải nghiệm điện ảnh tuyệt vời nhất cùng giá vé hấp dẫn, Cinestar Hai Bà Trưng là lựa chọn hoàn hảo cho những ai yêu thích phim màn ảnh rộng. </p>
                        </div>
                        <div className="career-item-footer">
                          <ul className="list">
                            <li className="item"><a className="link" href=""><span className="ic"><img src="/assets/images/ic-branch-call.svg" alt="" /></span><span className="txt">028 7300 7279</span></a></li>
                            <li className="item"><a className="link" href=""><span className="ic"><img src="/assets/images/ic-branch-map.svg" alt="" /></span><span className="txt">25 Hai Bà Trưng, Phường Vĩnh Ninh, TP.Huế</span></a></li>
                            <li className="item"><a className="link" href=""><span className="ic"><img src="/assets/images/ic-branch-fb.svg" alt="" /></span><span className="txt">Cinestar Huế</span></a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="branch-item address-item collapseItem" data-aos="fade-up">
                      <div className="address-box">
                        <div className="branch-heading address-box-head collapseHead">
                          <h4 className="sub-tittle">CINESTAR MỸ THO </h4><i className="fa-solid fa-angle-down icon"></i>
                        </div>
                        <div className="branch-item-body collapseBody">
                          <p className="desc"> Cinestar Hai Bà Trưng nằm tại trung tâm Quận 1, gần nhiều trường đại học, trung tâm văn hóa và thương mại. Với vị trí đắc địa này, Cinestar Hai Bà Trưng là điểm đến giải trí ưa thích của nhiều khán giả trẻ. Với cam kết mang đến những trải nghiệm điện ảnh tuyệt vời nhất cùng giá vé hấp dẫn, Cinestar Hai Bà Trưng là lựa chọn hoàn hảo cho những ai yêu thích phim màn ảnh rộng. </p>
                        </div>
                        <div className="career-item-footer">
                          <ul className="list">
                            <li className="item"><a className="link" href=""><span className="ic"><img src="/assets/images/ic-branch-call.svg" alt="" /></span><span className="txt">028 7300 7279</span></a></li>
                            <li className="item"><a className="link" href=""><span className="ic"><img src="/assets/images/ic-branch-map.svg" alt="" /></span><span className="txt">52 Đinh Bộ Lĩnh, Phường 3, TP. Mỹ Tho</span></a></li>
                            <li className="item"><a className="link" href=""><span className="ic"><img src="/assets/images/ic-branch-fb.svg" alt="" /></span><span className="txt">Cinestar Mỹ Tho</span></a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="branch-item address-item collapseItem" data-aos="fade-up">
                      <div className="address-box">
                        <div className="branch-heading address-box-head collapseHead">
                          <h4 className="sub-tittle">CINESTAR KIÊN GIANG </h4><i className="fa-solid fa-angle-down icon"></i>
                        </div>
                        <div className="branch-item-body collapseBody">
                          <p className="desc"> Cinestar Hai Bà Trưng nằm tại trung tâm Quận 1, gần nhiều trường đại học, trung tâm văn hóa và thương mại. Với vị trí đắc địa này, Cinestar Hai Bà Trưng là điểm đến giải trí ưa thích của nhiều khán giả trẻ. Với cam kết mang đến những trải nghiệm điện ảnh tuyệt vời nhất cùng giá vé hấp dẫn, Cinestar Hai Bà Trưng là lựa chọn hoàn hảo cho những ai yêu thích phim màn ảnh rộng. </p>
                        </div>
                        <div className="career-item-footer">
                          <ul className="list">
                            <li className="item"><a className="link" href=""><span className="ic"><img src="/assets/images/ic-branch-call.svg" alt="" /></span><span className="txt">4 phòng chiếu với 750 ghế</span></a></li>
                            <li className="item"><a className="link" href=""><span className="ic"><img src="/assets/images/ic-branch-map.svg" alt="" /></span><span className="txt">TTTM Rạch Sỏi, Phường Rạch Sỏi, TP. Rạch Giá</span></a></li>
                            <li className="item"><a className="link" href=""><span className="ic"><img src="/assets/images/ic-branch-fb.svg" alt="" /></span><span className="txt">Cinestar Kiêng Giang</span></a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="branch-item address-item collapseItem" data-aos="fade-up">
                      <div className="address-box">
                        <div className="branch-heading address-box-head collapseHead">
                          <h4 className="sub-tittle">CINESTAR LÂM ĐỒNG </h4><i className="fa-solid fa-angle-down icon"></i>
                        </div>
                        <div className="branch-item-body collapseBody">
                          <p className="desc"> Cinestar Hai Bà Trưng nằm tại trung tâm Quận 1, gần nhiều trường đại học, trung tâm văn hóa và thương mại. Với vị trí đắc địa này, Cinestar Hai Bà Trưng là điểm đến giải trí ưa thích của nhiều khán giả trẻ. Với cam kết mang đến những trải nghiệm điện ảnh tuyệt vời nhất cùng giá vé hấp dẫn, Cinestar Hai Bà Trưng là lựa chọn hoàn hảo cho những ai yêu thích phim màn ảnh rộng. </p>
                        </div>
                        <div className="career-item-footer">
                          <ul className="list">
                            <li className="item"><a className="link" href=""><span className="ic"><img src="/assets/images/ic-branch-call.svg" alt="" /></span><span className="txt">028 7300 7279</span></a></li>
                            <li className="item"><a className="link" href=""><span className="ic"><img src="/assets/images/ic-branch-map.svg" alt="" /></span><span className="txt">713 QL20, Liên NghĩaĐưc Trọng, Lâm Đồng</span></a></li>
                            <li className="item"><a className="link" href=""><span className="ic"><img src="/assets/images/ic-branch-fb.svg" alt="" /></span><span className="txt">Cinestar Lâm Đồng (Đức Trọng )</span></a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="branch-item address-item collapseItem" data-aos="fade-up">
                      <div className="address-box">
                        <div className="branch-heading address-box-head collapseHead">
                          <h4 className="sub-tittle">CINESTAR ĐÀ LẠT </h4><i className="fa-solid fa-angle-down icon"></i>
                        </div>
                        <div className="branch-item-body collapseBody">
                          <p className="desc"> Cinestar Hai Bà Trưng nằm tại trung tâm Quận 1, gần nhiều trường đại học, trung tâm văn hóa và thương mại. Với vị trí đắc địa này, Cinestar Hai Bà Trưng là điểm đến giải trí ưa thích của nhiều khán giả trẻ. Với cam kết mang đến những trải nghiệm điện ảnh tuyệt vời nhất cùng giá vé hấp dẫn, Cinestar Hai Bà Trưng là lựa chọn hoàn hảo cho những ai yêu thích phim màn ảnh rộng. </p>
                        </div>
                        <div className="career-item-footer">
                          <ul className="list">
                            <li className="item"><a className="link" href=""><span className="ic"><img src="/assets/images/ic-branch-call.svg" alt="" /></span><span className="txt"> 028 7300 7279</span></a></li>
                            <li className="item"><a className="link" href=""><span className="ic"><img src="/assets/images/ic-branch-map.svg" alt="" /></span><span className="txt">Quảng trường Lâm Viên, TP. Đà lạt</span></a></li>
                            <li className="item"><a className="link" href=""><span className="ic"><img src="/assets/images/ic-branch-fb.svg" alt="" /></span><span className="txt">Cinestar Đà Lạt</span></a></li>
                          </ul>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}